<template>
  <CCard>
    <CCardHeader class="d-flex ">
      <h2 v-if="!subId" class="mb-0">New Subscription</h2>
      <h2 v-else class="mb-0">Edit Subscription</h2>
    </CCardHeader>
    <CCardBody>
      <form @submit.prevent="submit" autocomplete="off" v-if="!this.subId || !this.user_loading">
        <CRow>
          <CCol md="6" lg="4">
            <label class="d-block">Expire At</label>
            <vc-date-picker v-model="new_subscription.expire_at" mode="dateTime" :is24hr="is24Hour" :min-date='new Date()'>
              <template v-slot="{ inputValue, inputEvents }">
                <input
                    class="form-control"
                    :value="formattedExpireDate"
                    v-on="inputEvents"
                />
              </template>
            </vc-date-picker>
          </CCol>
          <CCol md="6" lg="4">
            <CInput
                label="Quantity"
                type="number"
                min="1"
                v-model="new_subscription.quantity"
                autocomplete="email"
                :addInputClasses="{'is-invalid': hasError('quantity')}"
                :invalid-feedback="getError('quantity')"
            ></CInput>
          </CCol>
        </CRow>
        <div class="d-flex">

          <CButton type="submit" color="primary" class="mr-4">Save</CButton>
          <CButton color="secondary" @click="$router.push({name: 'Enterprise User Subscriptions', params: {id: id}})">Cancel</CButton>
        </div>
      </form>
      <div v-else class="mb-3">
        <CSpinner
            style="width:2rem;height:2rem;"
            color="primary"
            grow
        />
      </div>
    </CCardBody>
  </CCard>
</template>

<script>
import hasApiValidation from "@/mixins/hasApiValidation";

export default {
  name: "EditEnterpriseUserSubscription",
  mixins: [hasApiValidation],
  props: ['id', 'subId'],
  data() {
    return {
      roles: [],
      new_subscription: {
        expire_at: null,
        quantity: 1,
      },
      user_loading: false,
    }
  },
  mounted() {
    this.user_loading = true;
    if (this.subId) {
      // this.fetchSub(this.subId);
    }
  },
  computed: {
    formattedExpireDate(){
      return this.$formatDateTime(this.new_subscription.expire_at);
    },
    is24Hour() {
      return this.$is24Hours();
    }
  },
  methods: {
    submit() {
      this.save();
    },
    save() {
      this.setErrors({});
      this.$http.post(`/enterprise-users/${this.id}/subscriptions`, {
        ...this.new_subscription, 
        expire_at: this.new_subscription.expire_at 
        ? this.$moment(this.new_subscription.expire_at).utc().format("YYYY-MM-DD HH:mm:ss") 
        : null
      })
      .then(() => {
        this.$noty.success('Created');
        this.setErrors({});
        this.$router.push({name: 'Enterprise User Subscriptions', params: {id: this.id}});
      }).catch(({response}) => {
        // console.log(e)
        this.$noty.error(response.data.message);
        this.setErrors(response.data.errors);
      })
    },
  },
}
</script>

<style scoped>

</style>
