<template>
    <CModal :show.sync="showModal" centered  :title="title" size="lg">
        <CCardBody class="pt-0">
            <div>
                <CSelect v-model="newCategoryClass" :options="dataForSSelect(categoryClasses, 'Select Category of Class')"   
                label="Category of Class"
                @update:value="newCategoryClass = $event">

                </CSelect>
            </div>


            <div class="d-flex mt-3 align-items-center justify-content-center">
                <CButton color="secondary" class="mx-auto text-uppercase" @click="showModal = false">Cancel</CButton>
                <CButton color="primary" class="mx-auto text-uppercase btn_yes" @click="save()"> Add </CButton>
            </div>

        </CCardBody>
        <template #footer-wrapper>
            <div class="d-flex my-0 align-items-center justify-content-center">
            </div>
        </template>

    </CModal>

</template>

<script>

export default {
    name: "AddCategoryClassHome",
    data() {
        return {
            title: 'Add Category of Class',
            showModal: false,
            categoryClasses: [],
            newCategoryClass: null,

        }
    },
    methods: {
        save() {

            this.showModal = false;
            if (this.newCategoryClass) {
                this.$emit('operation-success', { success: true, categoryClass: this.newCategoryClass });
            }
        },
      
        async open() {
            this.newCategoryClass = null;
            try {
                const response = await this.axios.get('/categories');
                this.categoryClasses = response.data.data;
            } catch (error) {
                console.log(error);
            }
           
            this.showModal = true;
        },
        dataForSSelect(data, name = "Select") {
            let arr = [
                {
                    value: null,
                    label: name,
                    disabled: true
                }
            ];
            data.forEach(item => {
                arr.push({
                    value: item.id,
                    label: item.name
                    // text: item.name
                });
            });
            return arr;
        },

    }
}
</script>