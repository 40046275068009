<template>
    <CCard>
        <CCardHeader class="d-flex ">
            <h2 v-if="!id" class="mb-0">New Collection Category</h2>
            <h2 v-else class="mb-0">Edit Collection Category</h2>
        </CCardHeader>
        <CCardBody>
            <form @submit.prevent="submit" autocomplete="off" v-if="(!this.id || !this.loading)">
                <CRow>
                    <CCol lg="6">
                        <CInput label="Title" v-model="new_collection_category.title"
                            :addInputClasses="{ 'is-invalid': hasError('title') }"
                            :invalid-feedback="getError('title')"></CInput>
                    </CCol>
                </CRow>
                <CRow>
                    <CCol lg="6">
                        <label>Description</label>
                        <editor v-model="new_collection_category.description"  />
                        <div class="invalid-feedback d-block" v-if="hasError('description')">
                            {{ getError("description") }}
                        </div>
                    </CCol>
                    <CCol lg="6">
                        <CCard class="mb-3" style="height: 20rem;">
                            <CCardTitle>Image</CCardTitle>
                            <CCardImg orientation="top"
                                v-if="new_collection_category.image_base64 || new_collection_category.image_url"
                                :src="new_collection_category.image_base64 || new_collection_category.image_url"
                                style="height: 20rem;" />
                            <CCardHeader>
                                <input type="file" label="Image" @input="onFileChange($event)" />
                            </CCardHeader>
                        </CCard>
                        <div class="invalid-feedback d-block" v-if="hasError('image')">
                            {{ getError("image") }}
                        </div>
                    </CCol>
                </CRow>
                <!-- <CRow>
                    <CCol lg="6">
                        <CInput 
                            label="Order" 
                            type="number" 
                            v-model="new_collection_category.order" 
                            min="0" 
                            max="100" 
                            step="1" 
                            :addInputClasses="{ 'is-invalid': hasError('order') }"
                            :invalid-feedback="getError('order')"
                        />
                    </CCol>
                </CRow> -->
                <CButton type="submit" color="primary" class="mt-3">Save</CButton>
            </form>
            <div v-else class="mb-3">
                <CSpinner style="width:2rem;height:2rem;" color="primary" grow />
            </div>
        </CCardBody>
    </CCard>
</template>

<script>
import hasApiValidation from "@/mixins/hasApiValidation";
import EditorJodit from "../../components/EditorJodit.vue";

export default {
    name: "NewCollectionCategory",
    mixins: [hasApiValidation],
    props: ['id', 'currentPage'],
    components: {
        editor: EditorJodit,
    },
    data() {
        return {
            new_collection_category: {
                title: '',
                description: '',
                //  order: 0,
                image_base64: null,
                image_url: null,
                image: null,
            },
            
            loading: false,
            url: '/collection-categories'
        };
    },
    mounted() {
        if (this.id) {
            this.loading = true;
            this.fetchCollectionCategories(this.id);
        }
    },
    methods: {
        setCollectionCategory(data) {
            this.new_collection_category = {
                ...this.new_collection_category,
                ...data
            };

        },
        fetchCollectionCategories(id) {
            this.$http.get(`${this.url}/${this.id}`)
                .then(({ data }) => {
                    this.setCollectionCategory(data);
                })
                .catch(({ response }) => {
                    this.$router.push({ name: 'Categories' }).then(() => {
                        if (response && response.data && response.data.message) {
                            this.$noty.error(response.data.message);
                        }
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        createFormData() {
            const formData = new FormData();
            formData.append('title', this.new_collection_category.title);
            formData.append('description', this.new_collection_category.description);
            // formData.append('order', this.new_collection_category.order);

            if (this.new_collection_category.image) {
                formData.append('image', this.new_collection_category.image);
            }

            return formData;
        },
        update() {
            const formData = this.createFormData();

            this.$http.post(`${this.url}/${this.id}`, formData)
                .then(({ data }) => {
                    this.$noty.success("Updated");
                    this.$router.push({ name: 'Categories', params: { currentPage: this.currentPage } });
                })
                .catch(({ response }) => {
                    this.$noty.error(response.data.message);
                    this.setErrors(response.data.errors);
                });
        },
        submit() {
            this.setErrors({});
            if (this.id) {
                this.update();
            } else {
                this.save();
            }
        },
        save() {
            const formData = this.createFormData();

            this.$http.post(`${this.url}`, formData)
                .then(({ data }) => {
                    this.$noty.success("Created");
                    this.$router.push({ name: 'Categories' });

                })
                .catch(({ response }) => {
                    this.$noty.error(response.data.message);
                    this.setErrors(response.data.errors);
                });

        },
        onFileChange(event) {
            let file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.new_collection_category.image_base64 = e.target.result;
                };
                reader.readAsDataURL(file);

                this.new_collection_category.image = file;
            }
        }
    }
}

</script>