<template>
  <CCard>
    <CCardHeader class="d-flex justify-content-between">
      <h2 v-if="!id" class="mb-0">New Class</h2>
      <h2 v-else class="mb-0">Edit Class</h2>

      <CSelect class="mb-0 mr-3" :value="current_language" :disabled="this.loading || this.loading_data || !id"
        :options="[
          { label: 'English', value: 'en' },
          { label: 'Spanish', value: 'es' },
          { label: 'German', value: 'de' },
          { label: 'Dutch', value: 'nl' }
        ]" @update:value="updateLanguage">
      </CSelect>
    </CCardHeader>
    <CCardBody>
      <form @submit.prevent="submit" autocomplete="off" v-if="(!this.id || !this.loading) && !this.loading_data">
        <CRow>
          <CCol lg="6">
            <CInput label="Title" v-model="new_class.name" :addInputClasses="{ 'is-invalid': hasError('name') }"
              :invalid-feedback="getError('name')"></CInput>
            <div class="form-group" :class="{ 'is-invalid': hasError('instructors') }">
              <label>Instructors</label>
              <multi-select :options="dataForSelect(data.instructors)" :selected="new_class.instructors"
                :selection="true" :search="true" optionsEmptyPlaceholder="No options" searchPlaceholder="Search"
                selectionType="tags" @update="updateData($event, 'instructors')"></multi-select>
              <div class="invalid-feedback d-block" v-if="hasError('instructors')">
                {{ getError("instructors") }}
              </div>
            </div>

            <editor v-model="new_class.description" />
            <div class="row mt-4">
              <div class="col-md">
                <div><label>Duration</label></div>
                <div> {{ formattedDuration }}</div>
              </div>
              <div class="col-md">
                <div class="form-group">
                  <label>Production Date</label>
                  <vc-date-picker v-model="new_class.production_date" mode="dateTime" is24hr :masks="masks">
                    <template v-slot="{ inputValue, inputEvents }">
                      <input class="form-control " :value="formattedProductionDate" v-on="inputEvents" />
                    </template>
                  </vc-date-picker>
                </div>
              </div>

            </div>
            <div class="row mt-4">
              <div class="form-group">
                <label>Image</label>
                <CCard class="mb-3" style="height: 20rem;">
                  <CCardImg orientation="top" v-if="new_class.image_file" :src="new_class.image_file"
                    style="height: 20rem;" />
                  <CCardImg orientation="top" v-else-if="new_class.image" :src="new_class.image"
                    style="height: 20rem;" />
                  <CCardHeader>
                    <input type="file" label="Image" @input="onFileChange($event)" />
                  </CCardHeader>
                </CCard>
                <!-- <div class="invalid-feedback d-block" v-if="hasError('image')">
                  {{ getError("image") }}
                </div> -->
              </div>
            </div>

          </CCol>
          <CCol lg="6">
            <CSelect v-model="new_class.studio" :options="dataForSSelect(data.studios, 'Select Studio')" label="Studio"
              @update:value="updateData($event, 'studio')" :addInputClasses="{ 'is-invalid': hasError('studio') }"
              :invalid-feedback="getError('studio')"></CSelect>

            <div class="form-group" :class="{ 'is-invalid': hasError('categories') }">
              <label>Categories</label>
              <multi-select :options="dataForSelect(data.categories)" :selected="new_class.categories" :selection="true"
                optionsEmptyPlaceholder="No options" searchPlaceholder="Search" selectionType="tags" :search="true"
                @update="updateData($event, 'categories')"></multi-select>
              <div class="invalid-feedback d-block" v-if="hasError('categories')">
                {{ getError("categories") }}
              </div>
            </div>
            <div class="form-group s-select" :class="{ 'is-invalid': hasError('tags') }">
              <label>Tags</label>
              <multiselect v-model="new_class.tags" :options="dataForTagSelect(data.tags)"
                placeholder="Search or add a tag" :multiple="true" :taggable="true" @tag="addTag">
                <template slot="option" slot-scope="props">
                  <div data-v-6b8bd229="" tabindex="0" class="c-multi-select-option " :class="{
                    'c-multi-selected': new_class.tags.includes(props.option)
                  }">
                    {{
                      props.option && props.option.label
                        ? props.option.label
                        : props.option
                    }}
                  </div>
                </template>
              </multiselect>
              <div class="invalid-feedback d-block" v-if="hasError('tags')">
                {{ getError("tags") }}
              </div>
            </div>
            <CSelect v-model="new_class.class_type" :options="dataForSSelect(data.class_types, 'Select Class Type')"
              label="Class Type" @update:value="updateData($event, 'class_type')"
              :addInputClasses="{ 'is-invalid': hasError('class_type') }" :invalid-feedback="getError('class_type')">
            </CSelect>
            <CSelect v-model="new_class.language" :options="dataForSSelect(data.languages, 'Select Language')"
              label="Language" @update:value="updateData($event, 'language')"
              :addInputClasses="{ 'is-invalid': hasError('language') }" :invalid-feedback="getError('language')">
            </CSelect>
            <CSelect v-model="new_class.length" :options="dataForSSelect(data.length, 'Select Length')" label="Length"
              @update:value="updateData($event, 'length')" :addInputClasses="{ 'is-invalid': hasError('length') }"
              :invalid-feedback="getError('length')"></CSelect>
            <div class="form-group" :class="{ 'is-invalid': hasError('equipments') }">
              <label>Equipments</label>
              <multi-select :options="dataForSelect(data.equipment)" :selected="new_class.equipments" :selection="true"
                optionsEmptyPlaceholder="No options" searchPlaceholder="Search" selectionType="tags" :search="true"
                @update="updateData($event, 'equipments')"></multi-select>
              <div class="invalid-feedback d-block" v-if="hasError('equipments')">
                {{ getError("equipments") }}
              </div>
            </div>
            <div class="form-group" :class="{ 'is-invalid': hasError('props') }">
              <label>Props</label>
              <multi-select :options="dataForSelect(data.props)" :selected="new_class.props" :selection="true"
                optionsEmptyPlaceholder="No options" searchPlaceholder="Search" selectionType="tags" :search="true"
                @update="updateData($event, 'props')"></multi-select>
              <div class="invalid-feedback d-block" v-if="hasError('props')">
                {{ getError("props") }}
              </div>
            </div>
            <div class="form-group" :class="{ 'is-invalid': hasError('focus') }">
              <label>Focus</label>
              <multi-select :options="dataForSelect(data.focuses)" :selected="new_class.focus" :selection="true"
                optionsEmptyPlaceholder="No options" searchPlaceholder="Search" selectionType="tags" :search="true"
                @update="updateData($event, 'focus')"></multi-select>
              <div class="invalid-feedback d-block" v-if="hasError('focus')">
                {{ getError("focus") }}
              </div>
            </div>

            <div class="row">
              <!-- <div class="col-md-6">
                <div class="form-group">
                  <label>Production Date</label>
                  <vc-date-picker
                    v-model="new_class.production_date"
                    mode="dateTime"
                    is24hr
                    :masks="masks"
                  >
                    <template v-slot="{ inputValue, inputEvents }">
                      <input
                        class="form-control "
                        :value="inputValue"
                        v-on="inputEvents"
                      />
                    </template>
                  </vc-date-picker>
                </div>
              </div> -->
              <!-- <div class="col-md-6">
                <div class="form-group">
                  <label>Release Date</label>
                  <vc-date-picker
                    v-model="new_class.release_date"
                    mode="dateTime"
                    is24hr
                    :masks="masks"
                  >
                    <template v-slot="{ inputValue, inputEvents }">
                      <input
                        class="form-control "
                        :value="inputValue"
                        v-on="inputEvents"
                      />
                    </template>
                  </vc-date-picker>
                </div>
              </div> -->
            </div>
            <CInput v-model="new_class.vimeo_id" label="Vimeo ID"
              :addInputClasses="{ 'is-invalid': hasError('vimeo_id') }" :invalid-feedback="getError('vimeo_id')">
            </CInput>
          </CCol>
        </CRow>

        <CButton type="submit" color="primary" class="mt-3">Save</CButton>
      </form>
      <div v-else class="mb-3">
        <CSpinner style="width:2rem;height:2rem;" color="primary" grow />
      </div>
    </CCardBody>
  </CCard>
</template>

<script>
import hasApiValidation from "@/mixins/hasApiValidation";
import EditorJodit from "../../components/EditorJodit.vue";
import Multiselect from "vue-multiselect";
import MultiSelect from "@/components/MultiSelect";

export default {
  name: "NewAdmin",
  mixins: [hasApiValidation],
  props: ["id"],
  components: {
    MultiSelect,
    editor: EditorJodit,
    Multiselect
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEdited) {
      const answer = window.confirm(
        "You have unsaved changes. Are you sure you want to leave the page?"
      );
      if (answer) {
        return next();
      } else {
        return next(false);
      }
    } else {
      return next();
    }
  },
  data() {
    return {
      current_language: "en",
      roles: [],
      new_class: {
        name: "",
        description: "",
        instructors: [],
        tags: [],
        categories: [],
        class_type: null,
        length: null,
        language: null,
        equipments: [],
        props: [],
        focus: [],
        production_date: null,
        studio: null,
        release_date: null,
        vimeo_id: "",
        translations: {},
        duration: "",
        image_file: null,
        image: null
      },
      originData: {},
      masks: {
        inputDateTime24hr: "YYYY-MM-DD HH:mm"
      },
      data: {
        instructors: []
      },
      loading_data: true,
      loading: false
    };
  },
  computed: {
   
    formattedProductionDate() {
      return this.$formatDateTime(this.new_class.production_date);
    },
    isEnglish() {
      return this.current_language === "en";
    },
    isEdited() {
      const parser = data => JSON.stringify(data);
      return parser(this.originData) !== parser(this.new_class);
    },
    formattedDuration() {
      const duration = this.new_class?.duration ?? "";
      return duration ? duration.toString().replace(".", ":") : "";
    }
  },
  mounted() {
    this.setOriginData();
    if (this.id) {
      this.loading = true;
      this.fetchClass(this.id);
    }
    this.getData();
  },
  methods: {
    onFileChange(event) {
      const file = event.target.files[0];

      if (!file) return;

      // // Verificar que sea una imagen
      // if (!file.type.match('image.*')) {
      //   alert('Por favor selecciona un archivo de imagen');
      //   return;
      // }

      const reader = new FileReader();

      reader.onload = (e) => {
        // Asignar el resultado base64 al modelo
        this.new_class.image_file = e.target.result;
      };

      reader.readAsDataURL(file); // Convierte a base64
    },
    setOriginData() {
      this.originData = JSON.parse(JSON.stringify(this.new_class));
    },
    updateLanguage(value) {
      const prevLanguage = this.current_language;

      if (this.isEdited) {
        const answer = window.confirm(
          "You have unsaved changes! Are you sure you want to leave the page?"
        );
        if (!answer) {
          this.current_language = null;
          this.$nextTick(() => {
            this.current_language = prevLanguage;
          });
          return;
        }
      }

      this.current_language = value;

      const currentLanguageData = this.new_class.translations[value] || {};

      this.new_class.name = currentLanguageData?.name || "";
      this.new_class.description = currentLanguageData?.description || "";

      this.setOriginData();
    },
    setClassData(data) {
      this.new_class = {
        ...this.new_class,
        ...data.data,
        translations: {
          ...data.data.translations,
          en: {
            name: data.data.name,
            description: data.data.description
          }
        }
      };

      this.setOriginData();
    },
    fetchClass(id) {
      this.$http
        .get("/sessions/" + id)
        .then(({ data }) => {
          this.setClassData(data);
        })
        .catch(({ response }) => {
          this.$router.push({ name: "ClassList" }).then(() => {
            if (response && response.data && response.data.message) {
              this.$noty.error(response.data.message);
            }
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getData() {
      this.loading_data = true;
      this.$http
        .get("/sessions/data")
        .then(({ data }) => {
          this.data = data;
        })
        .finally(() => {
          this.loading_data = false;
        });
    },
    updateData(data, type) {
      this.new_class[type] = data;
    },
    submit() {
      this.setErrors({});

      if (this.id) {
        this.update();
      } else {
        this.save();
      }
    },
    save() {
      this.setOriginData();
      this.$http
        .post("/sessions", this.new_class)
        .then(({ data }) => {
          this.$noty.success("Created");
          const classId = data.data.id;
          this.$router.push({ name: "Edit Class", params: { id: classId } });
        })
        .catch(({ response }) => {
          this.$noty.error(response.data.message);
          this.setErrors(response.data.errors);
        });
    },
    async update() {
      let ignoreUpdate = false;
      const mainData = this.isEnglish
        ? this.new_class
        : {
          ...this.new_class,
          name: this.new_class.translations?.en.name,
          description: this.new_class.translations?.en.description
        };

      if (!this.isEnglish) {
        await this.$http
          .post(`/sessions/${this.id}/update-or-create-translations`, {
            language: this.current_language,
            name: this.new_class.name,
            description: this.new_class.description
          })
          .catch(({ response }) => {
            this.$noty.error(
              response.data.message ||
              "Error! Something went wrong with translation, please try again"
            );
            this.setErrors(response.data.errors);
            ignoreUpdate = true;
          });
      }

      if (ignoreUpdate) return;
      await this.$http
        .put("/sessions/" + this.id, mainData)
        .then(({ data }) => {
          this.setClassData(data);
          this.updateLanguage(this.current_language);
          this.$noty.success("Updated");
        })
        .catch(({ response }) => {
          this.$noty.error(response.data.message);
          this.setErrors(response.data.errors);
        });
    },
    dataForSelect(data) {
      return data.map(item => {
        return {
          value: item.id,
          // label: item.name,
          text: item.name
        };
      });
    },
    dataForSSelect(data, name = "Select") {
      let arr = [
        {
          value: null,
          label: name,
          disabled: true
        }
      ];
      data.forEach(item => {
        arr.push({
          value: item.id,
          label: item.name
          // text: item.name
        });
      });
      return arr;
    },
    dataForTagSelect(data) {
      return data && data.length ? data.map(item => item.name || item) : [];
    },
    addTag(newTagName) {
      this.data.tags.push({ id: null, name: newTagName });
      this.new_class.tags.push(newTagName);
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.form-group.is-invalid>>>.c-multi-select {
  border-color: var(--danger);
}

div.s-select>>>span.multiselect__option {
  padding: 0;
  background: transparent !important;
  color: #1a1a1c !important;
  font-size: 14px;
}

div.s-select>>>span.multiselect__option:after {
  content: unset !important;
}

div.s-select>>>.multiselect__option--selected {
  font-weight: 400;
}

div.s-select>>>.multiselect__content-wrapper {
  /* position: absolute; */
  top: 100%;
  left: 0;
  /* display: none; */
  float: left;
  width: 100%;
  padding: 0.5rem 0.75rem;
  /* margin-top: 0.625rem; */
  font-size: 0.875rem;
  text-align: left;
  background-clip: padding-box;
  border-radius: 0.25rem;
  color: #1a1a1c;
  background-color: #fff;
  border-color: #d8dbe0;
  border: 0;
  box-shadow: 0 4px 5px 0 rgba(var(--elevation-base-color), 0.14),
    0 1px 10px 0 rgba(var(--elevation-base-color), 0.12),
    0 2px 4px -1px rgba(var(--elevation-base-color), 0.2);
}

div.s-select>>>.c-multi-select-option::after,
div.s-select>>>.c-multi-select-option::before {
  top: 0.5rem;
}

div.s-select>>>.multiselect__tag {
  text-overflow: ellipsis;
  display: inline-block;
  height: 25px;
  margin: 2px;
  border: 1px solid;
  border-radius: 0.25rem;
  background-color: #ebedef;
  border-color: #d8dbe0;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  color: #768192;
}
</style>
