import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import babelPolyfill from 'babel-polyfill'
import router from './router'
import CoreuiVue from '@coreui/vue-pro'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
import axios from './request/index'
import configs from './config';
import VueNoty from 'vuejs-noty'
import moment from 'vue-moment'
import numeral from 'numeral';
import numFormat from 'vue-filter-number-format';
import VCalendar from 'v-calendar';
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import { Temporal } from '@js-temporal/polyfill';
import * as dateHelper from './helpers/dateHelper';


Vue.use(VueTelInput);


Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.prototype.$log = console.log.bind(console)
Vue.use(VueNoty, {
  closeWith: ['click'],
  callbacks: {
    onHover: function() {
      this.close()
    },
  }
});
Vue.use(moment);
Vue.filter('numFormat', numFormat(numeral));

Vue.filter('toHMTime', function (value) {
  var minutes = Math.floor((value) / 60);
  var seconds = value - (minutes * 60);

  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return minutes + ':' + seconds;
});

Vue.prototype.$formatDate = dateHelper.formatDate;
Vue.prototype.$formatDateTime = dateHelper.formatDateTime;
Vue.prototype.$is24Hours = dateHelper.use24HourFormat;
// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
  componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
});

const env = process.env.VUE_APP_ENV || "PROD"; 

const colors = {
  LOCAL: "#CCA438",
  PROD: "#ffffff",
  QA: "#CCA438",
};

document.documentElement.style.setProperty("--bg-color", colors[env]);

new Vue({
  el: '#app',
  router,
  store,
  icons,
  http:axios,
  configs:configs,
  template: '<App/>',
  components: {
    App
  },
})
