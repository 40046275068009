<template>


    <CCard>
        <div class="center-screen" v-if="loading">
            <CSpinner style="width:2rem;height: 2rem;" color="primary" />
        </div>
        <CCardHeader>
            <h2 class="text-uppercase font-weight-bold mb-0">{{ message }}</h2>
            <div class="d-flex flex-row-reverse ">
                <CButton class="mr-3" color="primary" @click="addCategoryClass()">Add Class Category</CButton>
                <CButton class="mr-3" color="primary" @click="addCategoryCollection()">Add Collection Category</CButton>
            </div>



        </CCardHeader>
        <CCardBody>
            <div class="position-relative table-reponsive">
                <table class="table">
                    <thead>
                        <tr class="text-uppercase">
                            <th>#</th>
                            <th>Name</th>
                            <th>Type</th>
                            <th class="d-flex justify-content-center">Actions</th>
                        </tr>
                    </thead>
                    <draggable v-model="data" tag="tbody" @end="saveOrder()">
                        <tr v-for="item in data" :key="'item-' + item.id">
                            <td scope="row">{{ item.order }}</td>
                            <td v-if="item.collection_category_id">{{ item.collection_category.title }}</td>
                            <td v-if="item.category_id">{{ item.category.name }}</td>
                            <td>{{ item.home_category_type.name }}</td>

                            <td style="width: 20%">
                                <div class="d-flex  align-items-center justify-content-end">
                                    <CButton size="sm" color="warning" class="text-white mr-2" v-if="item.show == 1"
                                        @click="show(item.id, item.show)">
                                        Hide
                                    </CButton>
                                    <CButton size="sm" color="success" class="text-white mr-2" v-else
                                        @click="show(item.id, item.show)">
                                        Show
                                    </CButton>

                                    <CButton size="sm" color="danger" class="text-white mr-2" @click="deleted(item.id)">
                                        Delete
                                    </CButton>

                                    <span class="mx-3">
                                        <i class="fas fa-arrows-alt"></i>
                                    </span>
                                </div>
                            </td>
                        </tr>
                    </draggable>
                </table>
            </div>


        </CCardBody>
        <delete-confirm-modal ref="delete_modal" @confirmed="confirmDelete"></delete-confirm-modal>
        <add-category-collection-home ref="add_cc_home" @operation-success="handleAddCategoryCollectionSuccess" />
        <add-category-class-home ref="add_ccls_home" @operation-success="handleAddCategoryClassSuccess" />
    </CCard>
</template>

<style>
.center-screen {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 9999;
    /* Asegúrate de que el spinner esté por encima de otros elementos */
}
</style>

<script>
import DeleteConfirmModal from "@/views/sessions/DeleteConfirmModal";
import draggable from 'vuedraggable';
import AddCategoryCollectionHome from './AddCategoryCollectionHome.vue';
import AddCategoryClassHome from './AddCategoryClassHome.vue';



export default {
    name: 'ConfigHome',
    components: { draggable, AddCategoryCollectionHome, AddCategoryClassHome, DeleteConfirmModal },
    data() {
        return {
            data: [],
            loading: false,
            fields: [
                { key: 'type', label: 'Type' },
                { key: 'description', label: 'Description' },
                { key: 'order', label: 'Order', sortable: false },
                { key: "actions", label: "Actions", sortable: false }
            ],
            message: 'Home list',
            urlHome: '/homes'
        }
    },
    async mounted() {

        await this.fetchData();
    },
    methods: {
        async saveOrder() {
            try {
                const response = await this.axios.put(`${this.urlHome}/order/reorder`, {home: this.data.map(item => item.id)});
                this.$noty.success('Order saved successfully');
                this.fetchData();
            } catch (error) {
                console.error(error);
                this.$noty.error('An error occurred while saving order');
            }
        },
        async fetchData() {
            try {
                this.loading = true;
                const response = await this.axios.get(this.urlHome);
                this.data = response.data;
                this.loading = false;
            } catch (error) {
                this.loading = false;
                console.error(error);
                this.$noty.error('An error occurred while fetching data');
            }
        },
        async show(id, show) {
            try {
                const dataShow = {
                    show: !show
                }
                const response = await this.axios.put(`${this.urlHome}/${id}/show`, dataShow);

                this.fetchData();

            } catch (error) {
                console.error(error);
                this.$noty.error('An error occurred ');
            }
        },
         deleted(id) {
            this.$refs.delete_modal.open(id);
        },
        async confirmDelete(id) {
            try {
                const response = await this.axios.delete(`${this.urlHome}/${id}`);
                this.fetchData();
            } catch (error) {
                console.error(error);
                this.$noty.error('An error occurred while deleting');
            }
        },
        async addCategoryCollection() {
            await this.$refs.add_cc_home.open();
        },
        async addCategoryClass() {
            await this.$refs.add_ccls_home.open();
        },
        async handleAddCategoryCollectionSuccess(event) {
            if (event.success && event.categoryCollection) {

                try {
                    const newOrder = Math.max(0, ...this.data.map(obj => obj.order)) + 1
                    const newHome = {
                        order: newOrder,
                        collection_category_id: event.categoryCollection,
                        home_category_type_id: 2,
                        category_id: null,
                    }
                    const response = await this.axios.post(this.urlHome, newHome);
                    this.$noty.success(`Category Collection added successfully`);
                    this.fetchData();
                } catch (error) {
                    console.error(error);
                    this.$noty.error('An error occurred while adding category collection');

                }


            }
        },
        async handleAddCategoryClassSuccess(event) {
            if (event.success && event.categoryClass) {
                try {
                    const newOrder = Math.max(0, ...this.data.map(obj => obj.order)) + 1
                    const newHome = {
                        order: newOrder,
                        collection_category_id: null,
                        home_category_type_id: 1,
                        category_id: event.categoryClass,
                    }
                    const response = await this.axios.post(this.urlHome, newHome);
                    this.$noty.success(`Category Class added successfully`);
                    this.fetchData();
                } catch (error) {
                    console.error(error);
                    this.$noty.error('An error occurred while adding category class');

                }
                
            }
        },
    }
}
</script>