<template>
  <CCard>
    <CCardHeader class="d-flex justify-content-end">
        <h2 class="text-uppercase font-weight-bold mb-0">List of Collections</h2>
        <CButton @click="newCollection" class="ml-auto mr-3" color="primary" >Add New</CButton>

        <!-- <CButton @click="openFilters" class="mr-3" color="primary">Filters</CButton> -->
        <CInput v-model="filters.search" @update:value="fetchData" class="mb-0" placeholder="Search"></CInput>
    </CCardHeader>
    <CCardBody>
      <CDataTable
        :items="data"
        :fields="fields"
        :pagination="false"
        :loading="loading"
        class="mb-0"

        >
        <template #statusDescription="data">
          <td> {{ data.item.status.description }} </td>
        </template>
        <template #items="data">
          <td> {{ data.item.items.length }} </td>
        </template>
        <template #release_at="data">
          <td> {{ $formatDateTime(data.item.release_at) }} </td>
        </template>
        <template #actions="data">
          <td>
            <div class="d-flex flex-nowrap">
              <CButton size="sm" color="success" class="text-white mr-2" @click="publish(data.item.id)" v-if="!data.item.release_at">Publish</CButton>
              <CButton size="sm" color="success" class="text-white mr-2" @click="unPublish(data.item.id)" v-if="data.item.release_at">Unpublish</CButton>
              <CDropdown
                color="info"
                size="sm"
                toggler-text=""
              >
                <template #toggler-content>
                  <span>Actions</span>
                </template>
                <CDropdownItem @click="editCollection(data.item.id, data.item.status_id)">Edit</CDropdownItem>
                <CDropdownItem @click="deleteCollection(data.item.id)">Delete</CDropdownItem>
                <CDropdownItem @click="addItems(data.item.id)">Add Items</CDropdownItem>
              </CDropdown>
            </div>
          </td>
        </template>
      </CDataTable>
      <div class="pagination-controls">
          <CButton @click="prevPage" color="primary" :disabled="!prevPageUrl">Previous</CButton>
          <CButton @click="nextPage" color="primary" :disabled="!nextPageUrl">Next</CButton>
        </div>
    </CCardBody>
    <delete-confirm-modal ref="delete_modal" @confirmed="confirmDelete"></delete-confirm-modal>
  </CCard>
</template>


<script>


import DeleteConfirmModal from "@/views/sessions/DeleteConfirmModal";
// import { EventBus } from "@/config/event-bus.js";



export default{
    name: "CollectionsList",
    components: {DeleteConfirmModal},
    props: ['currentPage'],
    data(){
      return {
        data: [],
        loading: false,
        // options: {
        //   page: 1,
        //   last_page: 1,
        //   per_page: 15,
        //   order_by: 'id'
        // },
        filters: {},
        fields: [
          { key: "title", label: "Title" },
          { key: "statusDescription", label: "Status" },
          { key: "items", label: "Classes" },
          { key: "release_at", label: "Release Date" },
          { key: "actions", label: "Actions", sortable: false }
        ],
        url:'/collections',
        itemsPerPage: 15,
        prevPageUrl: null,
        nextPageUrl: null
       };
    },
    methods:{
      addItems(id){
        this.$router.push({name: 'List Items', params: {id: id, currentPage: this.currentPage}});
      },
      editCollection(id, status_id){
        this.$router.push({name: 'Edit Collection', params: {id: id, currentPage: this.currentPage, status_id: status_id}});
      },
      newCollection(){
        this.$router.push({name: 'New Collection'});
      },
      prevPage() {
        if (this.prevPageUrl) {
          const urlParams = new URLSearchParams(this.prevPageUrl.split('?')[1]);
          const page = urlParams.get('page');
          this.currentPage = page;
          this.fetchData(page, this.itemsPerPage);
        }
      },
      nextPage() {
        if (this.nextPageUrl) {
          const urlParams = new URLSearchParams(this.nextPageUrl.split('?')[1]);
          const page = urlParams.get('page');
          this.currentPage = page;
          this.fetchData(page, this.itemsPerPage);
        }
      },
      fetchData(page,itemsPerPage) {
        this.loading = true;
        this.$http.get(`${this.url}?page=${page}&pageItems=${itemsPerPage}`).then(({data}) => {
          this.data = data.data;
          this.prevPageUrl = data.prev_page_url;
          this.nextPageUrl = data.next_page_url;
        })
          .catch(({response}) => {
            if (response && response.data && response.data.message) {
              this.$noty.error(response.data.message)
            }
          })
          .finally(() => {
            this.loading = false;
          });
      },
      publish(id){
        this.$http.post('/collections/' + id + '/publish').then(() => {
          this.$noty.success('Collection published successfully!');
          this.fetchData(this.currentPage, this.itemsPerPage);
        })
      },
      unPublish(id){
        this.$http.post('/collections/' + id + '/unpublish').then(() => {
          this.$noty.success('Collection upublished successfully!');
          this.fetchData(this.currentPage, this.itemsPerPage);
        })
      },
      deleteCollection(id) {
        this.$refs.delete_modal.open(id);
      },
      confirmDelete(id) {
        this.$http.delete('/collections/' + id + '').then(() => {
          this.$noty.success('Deleted!');
          this.fetchData(this.currentPage, this.itemsPerPage);
        });
      }
    //  sortingChange(val) {
    //   this.options.order_by = val.column;
    //   this.options.order = val.asc ? 'asc' : 'desc';
    //   this.fetchData(this.currentPage, this.itemsPerPage);
    // },
    },
    mounted()
    {
      this.fetchData(this.currentPage, this.itemsPerPage);
    }
};
</script>

<style scoped>

.pagination-controls {
  display: flex;
  justify-content: center;
  gap: 1rem; }

  CButton[disabled] {
    opacity: 0.5;
    pointer-events: none;
    }
</style>
