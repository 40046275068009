<template>
    <CModal :show.sync="showModal" centered  :title="title" size="lg">
        <CCardBody class="pt-0">
            <div>
                <CSelect v-model="newCategoryCollection" :options="dataForSSelect(categoryCollections, 'Select Category Collection')"   
                label="Category Collections"
                @update:value="newCategoryCollection = $event">

                </CSelect>
            </div>


            <div class="d-flex mt-3 align-items-center justify-content-center">
                <CButton color="secondary" class="mx-auto text-uppercase" @click="showModal = false">Cancel</CButton>
                <CButton color="primary" class="mx-auto text-uppercase btn_yes" @click="save()"> Add </CButton>
            </div>

        </CCardBody>
        <template #footer-wrapper>
            <div class="d-flex my-0 align-items-center justify-content-center">
            </div>
        </template>

    </CModal>

</template>

<script>

export default {
    name: "AddCategoryCollectionHome",
    data() {
        return {
            title: 'Add Category Collection',
            showModal: false,
            categoryCollections: [],
            newCategoryCollection: null,
            urlCategoryCollection: '/collection-categories',

        }
    },
    methods: {
        save() {
       
            this.showModal = false;
            if (this.newCategoryCollection) {
                this.$emit('operation-success', { success: true, categoryCollection: this.newCategoryCollection });
            }
           
        },
      
        async open() {
            this.newCategoryCollection = null;
            try {
                const response = await this.axios.get(this.urlCategoryCollection);
                this.categoryCollections = response.data.data;
            } catch (error) {
                console.log(error);
            }

            this.showModal = true;
        },
        dataForSSelect(data, name = "Select") {
            let arr = [
                {
                    value: null,
                    label: name,
                    disabled: true
                }
            ];
            data.forEach(item => {
                arr.push({
                    value: item.id,
                    label: item.title
                    // text: item.name
                });
            });
            return arr;
        },

    }
}
</script>