<template>
  <CModal
      :show.sync="showModal"
      :size="'sm'"
      centered
      @update:show="closeModal"
  >
    <template #header-wrapper>
      <CCardHeader>
        <h4 class="font-weight-bold text-center ">
          Select Release Date
        </h4>
      </CCardHeader>
    </template>
    <CCardBody class="pt-0">
      <div class="form-group"
           :class="{'is-invalid': hasError('release_date')}">
        <label>Release Date</label>
        <vc-date-picker v-model="item.release_date" mode="dateTime" is24hr :masks="masks">
          <template v-slot="{ inputValue, inputEvents }">
            <input
                class="form-control "
                :value="formattedReleaseDate"
                v-on="inputEvents"
            />
          </template>
        </vc-date-picker>
        <div class="invalid-feedback d-block" v-if="hasError('release_date')">
          {{ getError('release_date') }}
        </div>
      </div>

      <div class="d-flex mt-3 align-items-center justify-content-center">
        <CButton color="secondary" variant="outline" class="mx-auto text-uppercase"
                 @click="showModal = false"
        >
          Cancel
        </CButton>
        <CButton color="success" class="mx-auto text-uppercase"
                 @click="save()"
        >
          Yes
        </CButton>
      </div>
    </CCardBody>
    <template #footer-wrapper><span> </span></template>
  </CModal>
</template>

<script>
import hasApiValidation from "@/mixins/hasApiValidation";

export default {
  name: "ReleaseModal",
  mixins: [hasApiValidation],
  data() {
    return {
      item: {id: null, release_date: null},
      showModal: false,
      step: 1,
      finished: false,
      masks: {
        inputDateTime24hr: 'YYYY-MM-DD HH:mm',
      },
    }
  },
  methods: {
    open(item) {
      this.showModal = true;
      this.item.id = item.id;
    },
    save() {
      this.$http.put('/sessions/' + this.item.id + '/release', {release_date: this.item.release_date}).then((data) => {
        this.$noty.success('Updated');
        this.$emit('confirmed', this.item);
        this.showModal = false;
        this.item.id = null;
        this.item.release_date = null;
      }).catch(({response}) => {
        this.$noty.error(response.data.message);
        this.setErrors(response.data.errors);
      })
    },
    closeModal() {
      this.$emit('modal:close');
    }
  },
  computed: {
    formattedReleaseDate(){
      return this.$formatDateTime(this.item.release_date);
    },
    is24Hour() {
      return this.$is24Hours();
    }
  }
}
</script>

<style scoped>

</style>
