import Pagination from "@/components/Pagination";

export default {
  components: {Pagination},
  data() {
    return {
      data: [],
      options: {
        page: 1,
        last_page: 1,
        per_page: 15,
        order_by: 'id',
        order: 'desc'
      },
      filters: {},
      url: '/',
      loading: false,
    }
  },
  mounted() {
  },
  methods: {
    encodeParams(params) {
      let encodedParams = {};
  
      Object.keys(params).forEach(key => {
        if (typeof params[key] === "object" && params[key] !== null) {
          encodedParams[key] = this.encodeParams(params[key]);
        } else if (typeof params[key] === "string") {
          encodedParams[key] = encodeURIComponent(params[key]);
        } else {
          encodedParams[key] = params[key];
        }
      });
  
      return encodedParams;
    },
    fetchData() {
      this.loading = true;
      let encodedParams = this.encodeParams({ ...this.options, filters: { ...this.filters } });
      this.$http.get(this.url, {params: encodedParams}).then(({data}) => {
        this.data = data.data;
        this.options.page = data.meta.current_page;
        this.options.last_page = data.meta.last_page;
        this.options.per_page = data.meta.per_page;
      })
        .catch(({response}) => {
          if (response && response.data && response.data.message) {
            this.$noty.error(response.data.message)
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    sort() {

    },
    pageChange(val) {
      this.options.page = val;
      this.fetchData();
    },
    perPageChange(val) {
      this.options.per_page = val;
      this.fetchData();
    },
    sortingChange(val) {
      this.options.order_by = val.column;
      this.options.order = val.asc ? 'asc' : 'desc';
      this.fetchData();
    },
    searchChange(val) {
      this.options.s = val;
      this.options.page = 1;
      this.fetchData();
    },
  },
  computed: {
    currentPage() {
      return this.options.page;
    },
    pageCount() {
      return this.options.last_page;
    }
  }
}
