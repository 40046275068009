<template>
    <CModal :show.sync="showModal" centered @update:show="closeModal" :title="title" size="xl" :visible="true">

        <div v-if="loadinEdit">
            <CSpinner style="width:2rem;height:2rem;" color="primary" grow />
        </div>
        <CCardBody v-else-if="!loadinEdit" class="pt-0">
            <CRow>
                <CCol lg="12" class="d-flex justify-content-between align-items-center">
                    <div class="w-100 mx-auto">
                        <CInput label="Session search" class="w-100" v-model="search_criteria" />
                    </div>
                    <CButton color="primary" class="ml-3" @click="search">Search</CButton>
                </CCol>
            </CRow>
            <CRow>
                <CCol lg="12">
                    <CDataTable :items="data" :fields="fields" :pagination="true" :loading="loading"
                        :items-per-page="4">

                        <template #image="data">
                            <CImg :src="data.item.image" style="width: 5rem;" />
                        </template>

                        <template #actions="data">
                            <td>
                                <div class="d-flex flex-nowrap">
                                    <CButton size="sm" color="warning" class="text-white mr-2"
                                        @click="onRowSelected(data.item.id, data.item.name, data.item.description, data.item.image)">
                                        Select</CButton>
                                </div>
                            </td>
                        </template>

                    </CDataTable>
                </CCol>


            </CRow>
            <CRow>
                <CCol lg="12">
                    <CInput label="Title" v-model="new_session.title" />
                    <div class="invalid-feedback d-block" v-if="hasError('title')">
                        {{ getError("title") }}
                    </div>
                </CCol>
            </CRow>
            <CRow>
                <CCol lg="12">
                    <CInput label="order" v-model="order" />
                    <div class="invalid-feedback d-block" v-if="hasError('order')">
                        {{ getError("order") }}
                    </div>
                </CCol>
            </CRow>
            <CRow>
                <CCol lg="12">
                    <label>Description</label>
                    <editor v-model="new_session.description" />
                    <div class="invalid-feedback d-block" v-if="hasError('description')">
                        {{ getError("description") }}
                    </div>
                </CCol>
            </CRow>
            <CRow>
                <CCol lg="12">
                    <CCard>
                        <CCardTitle>Image</CCardTitle>
                        <CCardImg orientation="top" v-if="new_session.image_base64 || new_session.image_url"
                            :src="new_session.image_base64 || new_session.image_url" style="height: 20rem;" />
                        <CCardHeader>
                            <input type="file" label="Image" @input="onFileChange($event)" />
                        </CCardHeader>
                    </CCard>
                    <div class="invalid-feedback d-block" v-if="hasError('image')">
                        {{ getError("image") }}
                    </div>
                </CCol>
            </CRow>

            <div class="d-flex mt-3 align-items-center justify-content-center">
                <CButton color="secondary" class="mx-auto text-uppercase" @click="showModal = false">Cancel</CButton>
                <CButton color="primary" class="mx-auto text-uppercase btn_yes" @click="save()"> {{ id ? 'Save' : 'Add'
                    }}</CButton>
            </div>
        </CCardBody>
        <template #footer-wrapper>
            <div class="d-flex my-0 align-items-center justify-content-center">
            </div>
        </template>
    </CModal>

</template>

<script>

import EditorJodit from "../../components/EditorJodit.vue";
import hasApiValidation from "@/mixins/hasApiValidation";

export default {
    name: "SessionsModal",
    mixins: [hasApiValidation],
    components: {
        editor: EditorJodit
    },
    data() {
        return {

            selectedRowIds: null,
            search_criteria: '',
            showModal: false,
            title: '',
            id: null,
            collectionItemId: null,
            order: 1,
            idCollection: null,
            loading: false,
            loadinEdit: false,
            data: [],
            fields: [
                { key: "name", label: "Title" },
                { key: "image", label: "Image" },
                { key: "actions", label: "Actions", sortable: false }
            ],
            new_session: {
                title: '',
                description: '',
                session_id: null,
                image_base64: null,
                image_url: null,
                image: null
            },
            new_collection_item: {
                item_session_id: null,
                item_divider_id: null,
                collection_id: null,
                order: null,
            },
            urlAddSession: '/collection-item-sessions',
            urlAddCollectionItems: '/collection-items',
            urlSearchSessions: '/sessions'
        }
    },
    mounted() {


    },
    methods: {
        onFileChange(event) {
            let file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.new_session.image_base64 = e.target.result;
                };
                reader.readAsDataURL(file);
                this.new_session.image = file;
            }
        },
        onRowSelected(id, title, description, image_url) {
            this.selectedRowIds = id;
            this.new_session.title = title;
            this.new_session.description = description;
            this.new_session.image_url = image_url;
        },
        search() {
            this.loading = true;
            this.$http.get(`${this.urlSearchSessions}?filters[search]=${this.search_criteria}`)
                .then(({ data }) => {
                    this.data = data.data
                    this.loading = false;
                })
                .catch(({ response }) => {
                    this.$noty.error(response.data.message);
                    this.setErrors(response.data.errors);
                });
        },
        fetchData(id) {
            this.loadinEdit = true;
            this.$http.get(`${this.urlAddSession}/${id}`).then(({ data }) => {
                this.new_session.title = data.title;
                this.new_session.description = data.description;
                this.new_session.image_url = data.image_url;
                this.new_session.session_id = data.session_id;
                this.loadinEdit = false;
            });
        },
        closeModal() {
            this.showModal = false;
            this.clearData();
        },
        open(title, id, idCollection, order, collectionItemId) {
            this.showModal = true;
            this.title = title;
            this.id = id;
            this.order = order;
            this.collectionItemId = collectionItemId;
            this.idCollection = idCollection;
            if (this.id) {
                this.fetchData(id);
            }
        },
        save() {
            if (this.id) {
                this.update(this.id);
            } else {
                this.new();

            }
        },
        update(id) {
            let formData = new FormData();
            formData.append('title', this.new_session.title);
            formData.append('description', this.new_session.description);

            if (this.new_session.image) {
                formData.append('image', this.new_session.image);
            }

            this.$http.post(`${this.urlAddSession}/${id}`, formData).then(({ data }) => {
                this.new_collection_item.item_session_id = data.id;
                this.new_collection_item.collection_id = this.idCollection;
                this.new_collection_item.order = this.order;
                this.$http.put(`${this.urlAddCollectionItems}/${this.collectionItemId}`, this.new_collection_item)
                    .then(({ response }) => {

                        this.clearData();

                        this.$noty.success("Update!!");
                        this.showModal = false;
                        this.$emit('operation-success', { success: true });
                    })
            });
        },
        clearData() {
            this.new_session.title = '';
            this.new_session.description = '';
            this.new_session.session_id = null;
            this.new_session.image_base64 = null;
            this.new_session.image_url = null;
            this.new_session.image = null;

            this.new_collection_item.item_session_id = null;
            this.new_collection_item.item_divider_id = null;
            this.new_collection_item.collection_id = null;
            this.new_collection_item.order = null;
            this.id = null;
            this.collectionItemId = null;
            this.order = 1;
        },
        new() {
            let formData = new FormData();
            formData.append('title', this.new_session.title);
            formData.append('description', this.new_session.description);
            formData.append('session_id', this.selectedRowIds);

            if (this.new_session.image_url) {
                formData.append('image_url', this.new_session.image_url);
            } else if (this.new_session.image) {
                formData.append('image', this.new_session.image);
            }

            this.$http.post(`${this.urlAddSession}`, formData)
                .then(({ data }) => {
                    this.new_collection_item.item_session_id = data.id;
                    this.new_collection_item.collection_id = this.idCollection;
                    this.new_collection_item.order = this.order;
                    this.$http.post(`${this.urlAddCollectionItems}`, this.new_collection_item)
                        .then(({ datac }) => {

                            this.clearData();

                            this.$noty.success("Created");
                            this.showModal = false;

                            this.$emit('operation-success', { success: true });
                        })
                        .catch(({ response }) => {
                            this.$noty.error(response.data.message);
                            this.setErrors(response.data.errors);
                        });
                })
                .catch(({ response }) => {
                    this.$noty.error(response.data.message);
                    this.setErrors(response.data.errors);
                });
        }
    }
}
</script>

<style scoped></style>