<template>
  <CCard>
    <CCardHeader class="d-flex justify-content-between align-items-center">
        <h2 class="text-uppercase font-weight-bold mb-0">List of Items ({{ titleCollection }})</h2>
        <div class="ml-auto">
            <CButton @click="newDivider" class="mr-2" color="success">Add Divider</CButton>
            <CButton @click="newSession" color="primary">Add Session</CButton>
        </div>
    </CCardHeader>
    <CCardBody>
        <CDataTable
            :items="data"
            :fields="fields"
            :pagination="false"
            :loading="loading"
            class="mb-0"
        >
            <template #title="data">
                <td :class="{'bg-divider' : data.item.divider}" > {{ data.item.divider ? data.item.divider.title : data.item.session.title }} </td>
            </template>
            <template #description="data">

                <td :class="{'bg-divider' : data.item.divider}"> 
                   <div v-if="data.item.divider">{{data.item.divider.description}}</div> 
                    <EditorJodit v-if="data.item.session"  v-model="data.item.session.description" />                        
                </td>
            </template>
            <template #order="data">
                <td :class="{'bg-divider' : data.item.divider}" > {{ data.item.order }} </td>
            </template>
            <template #actions="data">
                <td>
                    <div class="d-flex flex-nowrap">
                        <CButton size="sm" color="warning" class="text-white mr-2" @click="editDivider(data.item.divider.id, data.item.order, data.item.id)" v-if="data.item.divider">Edit</CButton>
                        <CButton size="sm" color="warning" class="text-white mr-2" @click="editSession(data.item.session.id, data.item.order, data.item.id)" v-else-if="data.item.session">Edit</CButton>
                        <CButton size="sm" color="danger"  class="text-white mr-2" @click="deleteDivider(data.item.divider.id)" v-if="data.item.divider" >Deleted</CButton>
                        <CButton size="sm" color="danger"  class="text-white mr-2" @click="deleteSession(data.item.session.id)" v-else-if="data.item.session" >Deleted</CButton>
                    </div>
                </td>
            </template>
        </CDataTable>
    </CCardBody>
    <divider-modal ref="d_m" @operation-success="handleSuccess" />
    <sessions-modal ref="s_m" @operation-success="handleSessionSuccess"/>
    <delete-confirm-modal ref="delete_modal" @confirmed="confirmDelete"></delete-confirm-modal> 
    <delete-confirm-modal ref="delete_modal_session" @confirmed="confirmDeleteSession"></delete-confirm-modal> 
  </CCard>

</template>


<script>
import DeleteConfirmModal from "@/views/sessions/DeleteConfirmModal";
import EditorJodit from "../../components/EditorJodit.vue";
import DividerModal from "./DividerModal.vue";
import SessionsModal from "./SessionsModal.vue";




export default{
    name: "ItemsList",
    components: {DeleteConfirmModal, DividerModal, SessionsModal, EditorJodit},
    props: ['id','currentPage'],
    data(){
        return {
            data:[],
            loading: false,
            fields: [
                { key: "title", label: "Title"},
                { key: "description", label: "Description"},
                { key: "order", label: "Order"},
                { key: "actions", label: "Actions", sortable: false }
            ],
            titleCollection: '',
            urlCollections: '/collections',
            urlCollectionItemDivider: '/collection-item-dividers',
            urlCollectionItemSession: '/collection-item-sessions',
        };
    },
    // beforeDestroy() { 
    //     EventBus.$off('dataItems'); 
    // },
    methods: {
        deleteSession(id){
            this.$refs.delete_modal_session.open(id);
        },
        deleteDivider(id) {
            this.$refs.delete_modal.open(id);
        },
        confirmDeleteSession(id)
        {
            this.$http.delete(`${this.urlCollectionItemSession}/${id}`).then(() => {
                this.$noty.success('Deleted!');
                this.fetchItems();
            });
        },
        confirmDelete(id){
            this.$http.delete(`${this.urlCollectionItemDivider}/${id}`).then(() => {
                this.$noty.success('Deleted!');
                this.fetchItems();
            });
        },
        editSession(id, order, collectionItemId) {
            this.$refs.s_m.open('Edit Session', id, this.id, order, collectionItemId);
        },
        editDivider (id,  order, collectionItemId){
            this.$refs.d_m.open('Edit Divider', id, this.id, order, collectionItemId);
        },
        newDivider() {
            this.$refs.d_m.open('New Divider', null, this.id);
        },
        newSession() {
            this.$refs.s_m.open('New Session', null, this.id);
        },
        handleSuccess(event) {
            if (event.success) { 
                this.fetchItems(); 
            } 
        },
        handleSessionSuccess(event){
            if (event.success) { 
                this.fetchItems(); 
            } 
        },
        fetchItems() {
            this.loading = true;
            this.$http.get(`${this.urlCollections}/${this.id}`)
            .then(({data})=>{
                this.data = data.items;
                this.titleCollection = data.title;
            })
            .catch(({response}) => {
                if (response && response.data && response.data.message) {
                    this.$noty.error(response.data.message)
                }
            })
            .finally(() => {
                this.loading = false;
            });
        },
    },
    mounted(){
        
        // EventBus.$on('dataUpdate', (data) => { 
        //     this.titleCollection = data.collectionTitle; 
        // });
        
        this.fetchItems(); 
    }
}
</script>
<style scoped>
.bg-divider {
    background-color: #CCA438;
}
</style>