<template>
  <CCard>
    <CCardHeader class="d-flex justify-content-end align-items-center">
      <h2 class="text-uppercase font-weight-bold mb-0">List of Users</h2>
      <CButton @click="openFilters" class="ml-auto mr-3" color="primary">Filters</CButton>
      <CInput v-model="filters.search" @update:value="searchChange" class="mb-0" placeholder="Search"></CInput>
    </CCardHeader>
    <CCardHeader v-if="filters.active.length > 0">
      Status:
      <span v-for="status in filters.active"
            :key="'f-status-'+status"
            class="badge bg-info text-white mr-2 h6 c-pointer" @click="removeActiveFilter(status)">
                {{ statuses.find(s => s.value === status) ? statuses.find(s => s.value === status).label : '' }} <i
          class="fas fa-times"></i>
      </span>
    </CCardHeader>
    <CCardBody>
      <CDataTable
          :items="data"
          :fields="c_fields"
          :pagination="false"
          :sorter="{external: true}"
          :loading="loading"
          class="mb-0"
          @update:sorter-value="sortingChange"
      >

        <template #active="data">
          <td>
            {{ data.item.active ? 'Active' : 'Blocked' }}
          </td>
        </template>
        <template #email_verified_at="data">
          <td class="text-center">
            <span class="text-success" v-if="data.item.email_verified_at"><i class="far fa-check"></i> </span>
            <span class="text-danger" v-else><i class="far fa-times-circle"></i> </span>
          </td>
        </template>
        <template #created_at="data">
          <td class="text-nowrap">
            {{ $formatDateTime(data.item.created_at) }}
          </td>
        </template>
        <template #last_active="data">
          <td class="text-nowrap">
            {{ $formatDateTime(data.item.last_active) }}
          </td>
        </template>
        <template #linked_social_accounts_count="data">
          <td class="text-nowrap">
            <div class="d-flex align-items-center justify-content-between"
                 v-if="data.item.linked_social_accounts_count">
              <i class="fab fa-sm mx-1" v-for="acc in data.item.linked_social_accounts_count" :key="acc.id"
                 :class="{
                'fa-facebook': acc.provider_name === 'facebook',
                'fa-google': acc.provider_name === 'google',
                'fa-apple': acc.provider_name === 'apple',
                }"
                 :style="{
                  'font-size': acc.provider_name === 'apple' ? '1.1rem' : '',
                  'color': acc.provider_name === 'apple' ? 'gray' : (acc.provider_name === 'facebook' ? 'blue' : ''),
                 }"
              ></i>
            </div>
          </td>
        </template>
        <template #subscription_actions="data">
          <td>
            <div class="d-flex align-items-center">
              <span>
                {{ getSubType(data.item.admin_subscription_type, data.item.admin_subscription_expire_at) }}
              </span>
              <a href="#" @click.prevent="askSwap(data.item)"> <i class="far fa-pencil mx-3"></i>
              </a>
            </div>
          </td>
        </template>
        <template #actions2>
          <td>
            <div class="d-flex">
              <CButton @click="swapFields" class=" mr-3 text-nowrap " color="primary" size="sm">
                <span v-if="!subscriptions">Subscriptions</span>
                <span v-else>General info</span>
              </CButton>
            </div>
          </td>
        </template>
        <template #actions="data">
          <td>
            <div class="d-flex">
              <CButton @click="swapFields" class=" mr-3 text-nowrap" color="primary" size="sm">
                <span v-if="!subscriptions">Subscriptions</span>
                <span v-else>General info</span>
              </CButton>
              <CDropdown
                  color="info"
                  size="sm"
                  toggler-text=""
              >
                <template #toggler-content><span v-if="loadingActionUser !== data.item.id">Actions</span>
                  <c-spinner size="sm" v-else></c-spinner>
                </template>
                <CDropdownItem @click="editUser(data.item.id)">
                  Edit
                </CDropdownItem>
                <CDropdownItem @click="blockUser(data.item.id)" v-if="data.item.active">
                  Block
                </CDropdownItem>
                <CDropdownItem @click="unblockUser(data.item.id)" v-else>
                  Unblock
                </CDropdownItem>

                <CDropdownItem @click="resendVerification(data.item)"
                               v-if="data.item.email && !data.item.email_verified_at">Resend
                  Verification
                </CDropdownItem>
                <CDropdownItem @click="resetPassword(data.item)" v-if="data.item.email && data.item.email_verified_at">
                  Reset Password
                </CDropdownItem>
                <CDropdownItem @click="verify(data.item)" v-if="!data.item.email_verified_at">Verify</CDropdownItem>
                <CDropdownItem @click="deleteUser(data.item.id)">Delete User</CDropdownItem>
                <CDropdownItem @click="auth_admin(data.item)">Login as User</CDropdownItem>
              </CDropdown>
            </div>
          </td>
        </template>

        <template #subscription="data">
          <td class="text-nowrap">
            {{ data.item.plan ? data.item.plan.package_name : '' }}
          </td>
        </template>
        <template #subscription_type="data">
          <td class="text-nowrap">
            {{ data.item.plan ? (data.item.plan.type === 1 ? 'Full' : 'Basic') : '' }}
          </td>
        </template>
        <template #subscription_period="data">
          <td class="text-nowrap">
            {{ data.item.plan ? (data.item.plan.year ? 'Year' : 'Month') : '' }}
          </td>
        </template>
        <template #subscription_source="data">
          <td class="text-nowrap text-capitalize">
            {{ data.item.plan ? data.item.plan.platform : '' }}
          </td>
        </template>
        <template #subscription_dates="data">
          <td class="text-nowrap">
            <span v-if="data.item.plan">
              {{ $formatDateTime(data.item.plan.start_at) }} -
              {{ data.item.plan.ends_at ? $formatDateTime(data.item.plan.ends_at) : 'Running' }}
            </span>
          </td>
        </template>
        <template #family="data">
          <td class="text-nowrap">
            <template v-if="data.item.plan">
              <span
                  v-if="data.item.plan.owner_profile">Owner {{
                  data.item.plan.owner_profile.first_name
                }} {{ data.item.plan.owner_profile.last_name }}</span>
              <a href="#" @click.prevent="viewFamilyUsers(data.item)" v-else-if="data.item.family_users.length">Family
                users: {{ data.item.family_users.length }}</a>
            </template>
          </td>
        </template>
      </CDataTable>
      <pagination :current-page="options.page" @paginate="pageChange" :last-page="options.last_page" class="mr-3"/>
    </CCardBody>
    <admin-subscription-modal ref="a_s_m" @updated="fetchData"></admin-subscription-modal>
    <filters-modal ref="filters_modal" @apply="changeFilters"></filters-modal>
    <family-users-modal ref="family_users_modal"></family-users-modal>
    <delete-confirm-modal ref="block_modal" @confirmed="confirmBlockUser"></delete-confirm-modal>
    <delete-confirm-modal ref="delete_modal" @confirmed="confirmDeleteUser"></delete-confirm-modal>
  </CCard>
</template>

<script>
import externalTable from "@/mixins/externalTable";
import FiltersModal from "./FiltersModal";
import DeleteConfirmModal from "./DeleteConfirmModal";
import FamilyUsersModal from "@/views/users/FamilyUsersModal";
import AdminSubscriptionModal from "@/views/users/AdminSubscriptionModal";

export default {
  name: "UserList",
  components: {AdminSubscriptionModal, FamilyUsersModal, DeleteConfirmModal, FiltersModal},
  mixins: [externalTable],
  data() {
    return {
      subscriptions: false,
      filters: {
        search: '',
        active: [],
      },
      statuses: [
        // {value: null, label: 'All'},
        {value: 1, label: 'Active'},
        {value: 0, label: 'Blocked'},
      ],
      fields: [
        {
          key: 'profile.first_name', label: 'First Name',
        },
        {
          key: 'profile.last_name', label: 'Last Name',
        },
        {
          key: 'email', label: 'Email',
        },
        {
          key: 'last_active', label: 'Last Active'
        },
        {
          key: 'subscription', label: 'Subscription', sorter: false,
        },
        {
          key: 'email_verified_at', label: 'Verified'
        },
        {
          key: 'active', label: 'Status',
        },
      ],
      url: '/users',
      loadingActionUser: null,
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    getSubType(type, expire) {
      let str = '';
      let date = expire ? '(Expire at ' + $formatDateTime(expire)+')' : '';
      switch (type) {
        case 0:
          str = 'None'
          break;
        case 1:
          str = 'Basic'
          break;
        case 2:
          str = 'Full'
          break;
        case 3:
          str = 'SpinStudio'
          break;
      }
      return str + ' ' + date
    },
    askSwap(user) {
      this.$refs.a_s_m.open(user)
    },
    viewFamilyUsers(user) {
      this.$refs.family_users_modal.open(user);
    },

    resendVerification(user) {
      this.loadingActionUser = user.id;
      this.$http.put('/users/' + user.id + '/resend-verification').then(() => {
        this.$noty.success('Email sent!');
      })
          .catch(({response}) => {
            this.$noty.error(response.data.message)
          })
          .finally(() => {
            this.fetchData()
            this.loadingActionUser = null;
          })
    },
    resetPassword(user) {
      this.loadingActionUser = user.id;
      this.$http.put('/users/' + user.id + '/reset-password').then(() => {
        this.$noty.success('Email sent!');
      })
          .catch(({response}) => {
            this.$noty.error(response.data.message)
          })
          .finally(() => {
            this.fetchData()
            this.loadingActionUser = null;
          })
    },
    verify(user) {
      this.loadingActionUser = user.id;
      this.$http.put('/users/' + user.id + '/verify').then(() => {
        this.$noty.success('Email sent!');
      })
          .catch(({response}) => {
            this.$noty.error(response.data.message)
          })
          .finally(() => {
            this.fetchData()
            this.loadingActionUser = null;
          })

    },
    removeActiveFilter(status) {
      this.filters.active = this.filters.active.filter(r => r !== status);
      this.fetchData();
    },
    swapFields() {
      // this.options.page = 1;
      this.subscriptions = !this.subscriptions;
      // this.fetchData();
    },
    openFilters() {
      this.$refs.filters_modal.open(this.filters);
    },
    changeFilters(data) {
      this.filters = Object.assign({}, this.filters, data);
      this.fetchData();
    },
    createUser() {
      this.$router.push({name: 'New User'});
    },
    editUser(id) {
      this.$router.push({name: 'Edit User', params: {id: id}});
    },
    blockUser(id) {
      this.$refs.block_modal.open(id);
    },
    deleteUser(id) {
      this.loadingActionUser = id;
      this.$http.get('/users/' + id + '/delete').then(() => {
        this.loadingActionUser = null;
        this.$refs.delete_modal.open(id);
      })
        .catch(() => {
          this.$refs.delete_modal.open(id, true);
        })
        .finally(() => {
          this.loadingActionUser = null;
        })
    },
    confirmBlockUser(id) {
      this.$http.put('/users/' + id + '/block').then(() => {
        this.$noty.success('Blocked!');
        this.fetchData()
      })
    },
    unblockUser(id) {
      this.$http.put('/users/' + id + '/unblock').then(() => {
        this.$noty.success('Unblocked!');
        this.fetchData()
      })
    },
    confirmDeleteUser(id) {
      this.$http.delete('/users/' + id).then(() => {
        this.$noty.success('Deleted!');
        this.fetchData()
      })
    },
    auth_admin(user) {
      this.loadingActionUser = user.id;
      this.$http.put('/users/' + user.id + '/auth').then(({data}) => {
        this.loadingActionUser = null;
        window.open(data.link, '_blank', 'noreferrer');
      })
        .catch(({response}) => {
          this.$noty.error(response.data.message)
          this.loadingActionUser = null;
        })
    }
  },
  computed: {
    c_fields() {
      let fields = [
        {
          key: 'profile.first_name', label: 'First Name',
        },
        {
          key: 'profile.last_name', label: 'Last Name',
        },
        {
          key: 'email', label: 'Email',
        },
      ];
      if (this.subscriptions) {
        fields = fields.concat([
          {
            key: 'subscription_type', label: 'Type', sorter: false,
          },
          {
            key: 'subscription_period', label: 'Period', sorter: false,
          },
          {
            key: 'subscription_source', label: 'Source', sorter: false,
          },
          {
            key: 'subscription_dates', label: 'Dates', sorter: false,
          },
          {
            key: 'family', label: 'Family', sorter: false,
          },
        ])
        if (this.$auth.check('users_settings')) {
          fields.push(
              {
                key: 'subscription_actions', label: 'Admin Subscription Type',
              },
          );
        }

        fields.push(
          {
            key: 'actions2', label: 'Actions',
          }
        );
      } else {
        fields = fields.concat([
          {
            key: 'created_at', label: 'Registration Date',
          },
          {
            key: 'last_active', label: 'Last Active'
          },
          {
            key: 'linked_social_accounts_count', label: 'SSO'
          },
          {
            key: 'email_verified_at', label: 'Verified'
          },
          {
            key: 'active', label: 'Status',
          },
        ])
        if (this.$auth.check('users_crud')) {
          fields.push(
              {
                key: 'actions', label: 'Actions',
              }
          );
        }
      }
      return fields
    }
  }
}
</script>

<style scoped>
.fa-google {
  background: conic-gradient(from -45deg, #ea4335 110deg, #4285f4 90deg 180deg, #34a853 180deg 270deg, #fbbc05 270deg) 73% 55%/150% 150% no-repeat;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
}
</style>
