<template>
  <CCard>
    <CCardHeader class="d-flex justify-content-between">
      <h2 v-if="!id" class="mb-0">New Category</h2>
      <h2 v-else class="mb-0">Edit Category</h2>

      <CSelect class="mb-0 mr-3" :value="current_language" :options="[
        { label: 'English', value: 'en' },
        { label: 'Spanish', value: 'es' },
        { label: 'German', value: 'de' },
        { label: 'Dutch', value: 'nl' }
      ]" :disabled="!id" @update:value="updateLanguage">
      </CSelect>
    </CCardHeader>
    <CCardBody>
      <form @submit.prevent="submit" autocomplete="off" v-if="!this.id || !this.loading">

        <div class="d-flex flex-column">
          <CInput label="Name" v-model="new_category.name" :addInputClasses="{ 'is-invalid': hasError('name') }"
            :invalid-feedback="getError('name')"></CInput>
        </div>
        <div class="row">
          <div class="col-md-8 mb-3">
            <editor v-model="new_category.description" />
            <div class="invalid-feedback d-block" v-if="hasError('description')">
              {{ getError("description") }}
            </div>
          </div>
          <div class="col-md-4 mb-3">
            <CCard>
              <CCardImg orientation="top" style="height: 18rem;"
                v-if="new_category.image_base64 || new_category.image_url"
                :src="new_category.image_base64 || new_category.image_url" />

              <CCardBody>
                <input type="file" label="Image" @input="onFileChange($event)" />
                <div class="invalid-feedback d-block" v-if="hasError('image')">
                  {{ getError("image") }}
                </div>
              </CCardBody>
            </CCard>

          </div>

        </div>
        <div>
          <CButton type="submit" color="primary" class="mt-3">Save</CButton>
        </div>

      </form>
      <div v-else class="mb-3">
        <CSpinner style="width:2rem;height:2rem;" color="primary" grow />
      </div>
    </CCardBody>
  </CCard>
</template>

<script>
import hasApiValidation from "@/mixins/hasApiValidation";
import { CCardBody } from "@coreui/vue-pro";
import EditorJodit from "../../components/EditorJodit.vue";

export default {
  name: "NewCategory",
  mixins: [hasApiValidation],
  props: ["id"],
  components: {
    editor: EditorJodit
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEdited) {
      const answer = window.confirm(
        "You have unsaved changes. Are you sure you want to leave the page?"
      );
      if (answer) {
        return next();
      } else {
        return next(false);
      }
    } else {
      return next();
    }
  },
  data() {
    return {
      roles: [],
      current_language: "en",
      originData: {},
      new_category: {
        name: "",
        description: "",
        image_base64: null,
        image_url: null,
        image: null,
      },
      loading_data: true,
      loading: false
    };
  },
  computed: {
    isEnglish() {
      return this.current_language === "en";
    },
    isEdited() {
      const parser = data => JSON.stringify(data);
      return parser(this.originData) !== parser(this.new_category);
    }
  },
  mounted() {
    this.setOriginData();
    if (this.id) {
      this.loading = true;
      this.fetchClass(this.id);
    }
  },
  methods: {
    onFileChange(event) {
      let file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.new_category.image_base64 = e.target.result;
        };
        reader.readAsDataURL(file);

        this.new_category.image = file;
      }
    },
    setOriginData() {
      this.originData = JSON.parse(JSON.stringify(this.new_category));
    },
    setCategoryData(data) {
      this.new_category = {
        ...this.new_category,
        ...data.data,
        translations: {
          ...data.data.translations,
          en: {
            name: data.data.name,
            description: data.data.description
          }
        }
      };

      this.setOriginData();
    },
    updateLanguage(value) {
      this.setErrors({});
      const prevLanguage = this.current_language;

      if (this.isEdited) {
        const answer = window.confirm(
          "You have unsaved changes! Are you sure you want to leave the page?"
        );
        if (!answer) {
          this.current_language = null;
          this.$nextTick(() => {
            this.current_language = prevLanguage;
          });
          return;
        }
      }

      this.current_language = value;

      const currentLanguageData = this.new_category.translations[value] || {};

      this.new_category.name = currentLanguageData?.name || "";
      this.new_category.description = currentLanguageData?.description || "";

      this.setOriginData();
    },
    fetchClass(id) {
      this.$http
        .get("/categories/" + id)
        .then(({ data }) => {
          this.setCategoryData(data);
          this.setOriginData();
        })
        .catch(() => {
          this.$router.push({ name: "ClassCategories" }).then(() => {
            this.$noty.error("Not Found");
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    submit() {
      this.setErrors({});

      if (this.id) {
        this.update();
      } else {
        this.save();
      }
    },
    save() {
      this.setOriginData();
      const formData = new FormData();
      formData.append("name", this.new_category.name);
      formData.append("description", this.new_category.description);
      formData.append("image", this.new_category.image);

      this.$http
        .post("/categories/", formData)
        .then(({ data }) => {
          const categoryId = data.data?.id;
          this.$noty.success("Created");
          this.$router.push({
            name: "Edit Category",
            params: { id: categoryId }
          });
        })
        .catch(({ response }) => {
          this.$noty.error(response.data.message);
          this.setErrors(response.data.errors);
        });
    },
    update() {
      let action;
      const formData = new FormData();
      formData.append("name", this.new_category.name);
      formData.append("description", this.new_category.description);

      if (this.new_category.image) {
        formData.append("image", this.new_category.image);
      }


      if (this.isEnglish) {
        action = this.$http.post("/categories/" + this.id, formData);
      } else {
        action = this.$http.post(
          `/categories/${this.id}/update-or-create-translations`,
          {
            language: this.current_language,
            name: this.new_category.name,
            description: this.new_category.description
          }
        );
      }

      action
        .then(({ data }) => {
          this.setCategoryData(data);
          this.updateLanguage(this.current_language);
          this.$noty.success("Changes have been saved successfully");
        })
        .catch(({ response }) => {
          this.$noty.error(response.data.message);
          this.setErrors(response.data.errors);
        });
    }
  }
};
</script>
